import {initMenu} from "./menu";
import { Swiper } from 'swiper';
import {Autoplay, Mousewheel} from "swiper/modules";

initMenu();

const mapSlider = document.querySelector('.swiper');

if (mapSlider) {
    new Swiper('.swiper', {
        loop: true,
        modules: [Autoplay, Mousewheel],
        centeredSlides: true,
        mousewheel: true,
        autoplay: {
            delay: 3000,
            disableOnInteraction: false,
            speed: 1000,
        }
    });
}
