const NAV_ACTIVE_CLASS = 'header-nav--active';
const TRIGGER_ACTIVE_CLASS = 'header-trigger--active';

export const initMenu = () => {
    const nav = document.querySelector('#nav');
    const navTrigger = document.querySelector('#navTrigger');

    navTrigger.addEventListener('click', () => {
        navTrigger.classList.toggle(TRIGGER_ACTIVE_CLASS);
        nav.classList.toggle(NAV_ACTIVE_CLASS);

        // TODO: remove later
        navTrigger.classList.toggle('trigger--active');
        nav.classList.toggle('nav--active');
    });

    document.addEventListener('click', (e) => {
        // TODO: remove later
        if (!nav.classList.contains('nav--active')) return;

        if (!nav.classList.contains(NAV_ACTIVE_CLASS)) return;
        if (e.target.tagName !== 'A' && e.target.closest('#header')) return;

        navTrigger.classList.remove(TRIGGER_ACTIVE_CLASS);
        nav.classList.remove(NAV_ACTIVE_CLASS);

        // TODO: remove later
        navTrigger.classList.remove('trigger--active');
        nav.classList.remove('nav--active');
    })
};
